<template>
  <div class="enroll-form-item">
    <el-form
      label-position="top"
      class="q-custom-form"
      size="mini"
      :model="questionaireForm"
      :rules="step_2_rules"
      ref="questionaireForm"
      :disabled="disableEdit || disableNSCLCOperation"
      :validate-on-rule-change="false"
    >
      <template v-for="(question, index) in questionaireConfig.question_list">
        <div :key="question.question_id" class="define-form-item" style="flex-direction: column">
          <div style="display: flex">
            <span class="sortNo">{{ handleSortNo(index) }}</span>
            <el-form-item :label="question.question_title" :required="true" />
          </div>
          <div class="define-form-description">
            <div v-html="question.question_description"></div>
          </div>
          <div style="padding-left: 15px">
            <el-row class="define-form-item-520-title">是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;否</el-row>
            <el-row v-for="child in question.children_list" :key="child.question_id" class="define-form-item-520">
              <el-form-item :label="child.question_title" :prop="child.question_code" :inline-message="true">
                <el-radio-group v-model="questionaireForm[child.question_code]">
                  <el-radio v-for="option in child.option_list" :key="option.option_id" :label="option.option_code">{{ '' }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
          </div>
        </div>
      </template>
    </el-form>
    <div class="custom-form-footer">
      <template v-if="!initLoading && !isCanJoin && !disableNSCLCOperation">
        <el-button v-if="disableEdit" class="custom-submit-btn" @click="changeEditStatus">编辑</el-button>
        <el-button v-else class="custom-submit-btn" type="primary" @click="handleConfirm" :loading="loading">提交</el-button>
      </template>
    </div>
  </div>
</template>
<script>
import {
  apiCanJoinGroup,
  apiGetTaskQuestionnaire
} from '@/apis/NSCLC/modalQuestionaire'
import {
  getQuestionaireInfo,
  getQuestionnareResult,
  submitResult
} from '@/apis/questionnaire'
import store from '@/store'

export default {
  props: {
    taskCode: {
      type: String,
      default: ''
    },
    patientId: {
      type: String,
      default: ''
    },
    taskStatus: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      questionaireForm: {},
      questionaireConfig: {},
      question_type_set: {},
      loading: false,
      disableEdit: false,
      isCanJoin: false, // 可以入组
      step_2_exclude: [],
      resultId: '',
      initLoading: false
    }
  },
  computed: {
    step_2_rules() {
      if (this.questionaireConfig?.question_list) {
        let info = {}
        this.questionaireConfig.question_list.map(ii => {
          if (ii.frontend_question_type !== 1) {
            if (ii.frontend_question_type == 510) {
              // 矩阵单选
              ii.children_list.map(jj => {
                if (jj.required_type == 1) {
                  info[jj.question_code] = [
                    {
                      required: true, message: this.handleTips(jj.frontend_question_type), trigger: 'change'
                    }
                  ]
                }
              })
            } else {
              if (ii.required_type === 1) {
                info[ii.question_code] = [
                  {
                    required: true, message: this.handleTips(ii.frontend_question_type), trigger: 'change'
                  }
                ]
              } else {
                info[ii.question_code] = []
              }
            }
          }
        })
        return info
      } else {
        return {}
      }
    },
    disableNSCLCOperation() {
      return store.state.user.disableNSCLCOperation
    }
  },
  created() {
    if (this.taskCode) {
      this.getDetail()
    }
  },
  watch: {
    taskCode(val) {
      if (val) {
        this.getDetail()
      }
    }
  },
  methods: {
    async getDetail() {
      this.initLoading = true
      const params = {
        patient_id: this.patientId,
        task_code: this.taskCode
      }
      const res = await apiGetTaskQuestionnaire(params)
      if (res.code === 200) {
        this.resultId = res.entity.result_id
        this.handleQuestionaire(res.entity.questionnaire_code, res.entity.result_id)
      } else {
        this.initLoading = false
        this.$message.error(res.message)
      }
      this.$nextTick(() => {
        this.$refs.questionaireForm && this.$refs.questionaireForm.clearValidate()
      })
      if (this.taskStatus === '30') {
        this.disableEdit = true
      } else {
        this.disableEdit = false
      }
    },
    async handleQuestionaire(kind, result_id) {
      const result = await getQuestionaireInfo(kind, result_id)
      if (result.code === 200) {
        this[`questionaireConfig`] = result.entity.questionnaire_info || []
        this[`questionaireConfig`].question_list.map((ii, index) => {
          if (ii.frontend_question_type === 1) {
            this[`step_${kind}_exclude`].push(index)
          }
          if (ii.frontend_question_type === 510) {
            ii.children_list.map(yy => {
              this.question_type_set[yy.question_code] = yy.frontend_question_type
            })
          } else {
            this.question_type_set[ii.question_code] = ii.frontend_question_type
          }
        })
      }
      // 获取问卷已提交答案
      const otherResult = await getQuestionnareResult(result_id)
      this.initLoading = false
      if (otherResult.code === 200) {
        this.isCanJoin = otherResult.entities[0].questionnaire_info.point === 100
        const answerInfo = otherResult.entities[0].answer_list
        const answerKeyValue = {}
        answerInfo.map(ii => {
          let newVal = ii.filled_key || ii.filled_value
          if (answerKeyValue[ii.question_code]) {
            // 值已记录
            let temp = answerKeyValue[ii.question_code]
            if (typeof temp === 'string') {
              // 表示原题是个多选
              answerKeyValue[ii.question_code] = [temp, newVal]
            } else if (Array.isArray(temp)) {
              // 表示原题是个多选
              temp.push(newVal)
              answerKeyValue[ii.question_code] = temp
            }
          } else {
            // 值没记录
            answerKeyValue[ii.question_code] = newVal
          }
        })
        Object.keys(answerKeyValue).forEach(key => {
          this.$set(this[`questionaireForm`], key, answerKeyValue[key])
        })
      }
    },
    handleSortNo(index) {
      return index + 1 > 9 ? index + 1 : '0' + (index + 1)
    },
    handleConfirm() {
      this.$refs['questionaireForm'].validate(async valid => {
        if (valid) {
          await this.handleSubmit()
        } else {
          this.$message({
            type: 'error',
            message: '您有必填字段未填写。'
          })
        }
      })
    },
    handleSubmitParams() {
      const params = {
        result_info: {
          result_id: this.resultId
        },
        answer_list: []
      }
      let temp = []
      Object.keys(this.questionaireForm).forEach(key => {
        if (this.question_type_set[key] == 200) {
          // 输入框
          temp.push({
            multiple_index: 0,
            filled_key: "",
            question_code: key,
            filled_description: "",
            filled_value: this.questionaireForm[key] + '',
            parent_code: "",
            sort_no: 0
          })
        } else {
          // 单选
          temp.push({
            multiple_index: 0,
            filled_key: this.questionaireForm[key],
            question_code: key,
            filled_description: "",
            filled_value: "",
            parent_code: "",
            sort_no: 0
          })
        }
      })
      params['answer_list'] = temp
      return params
    },
    async handleSubmit() {
      this.loading = true
      const params = this.handleSubmitParams()

      // 先判断是否可以入组
      const res = await apiCanJoinGroup(params)
      if (res.code === 200) {
        this.isCanJoin = res.entity.canJoin
        if (!res.entity.canJoin) {
          this.$confirm('该受试者不符合入组标准，请确认。', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.handleConfirmSubmit(params)
          }).catch(() => {
            this.loading = false
          })
        } else {
          this.handleConfirmSubmit(params)
        }
      }
    },
    async handleConfirmSubmit(params) {
      const result = await submitResult(params)
      this.loading = false
      if (result.code === 200) {
        this.disableEdit = true
        this.$message.success('提交成功')
        this.$emit('goNext')
      } else {
        this.$message.error(result.message)
      }
    },
    changeEditStatus() {
      this.disableEdit = false
    },
    handleTips(type) {
      if (type === 200) {
        return '请输入'
      } else {
        return '请选择'
      }
    },
    handleDialogMessageConfirm() {
      this.step_error = false
    },
    handleDialogMessageCancel() {
      this.step_error = false
    },
    async handleDialogMessageAction(action) {
      if (action === 'disenroll') {
        const params = this.handleSubmitParams()
        // 标记未入组
        params.result_info['confirm_not_grouped'] = '1'
        this.handleConfirmSubmit(params)
      }
      this.step_error = false
    },
  }
}
</script>
<style lang="scss" scoped>
.enroll-form-item {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.custom-form-footer {
  border-top: 1px solid #d4d4d4;
  text-align: center;
  padding: 10px 20px;
  position: absolute;
  flex-shrink: 0;
  height: 60px;
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
  background: #fff;
}
.custom-submit-btn {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-60px);
  width: 120px;
}
</style>
<style lang="scss">
.enroll-form-item {
  .q-custom-form {
    padding: 0 0 60px 0px;
    .el-row.define-form-item-520-title {
      padding-left: 80%;
      white-space: pre-wrap;
      margin-top: 5px;
    }
    .el-row.define-form-item-520 > .el-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      > label.el-form-item__label {
        padding-bottom: 0;
        padding-right: 24px;
        width: 80%;
        &::before {
          content: '';
          margin-right: 0;
        }
      }
    }
  }
}
</style>