<template>
  <div class="log-list-container">
    <el-form :inline="true" class="my-row" :model="searchForm" size="mini">
      <div></div>
      <div>
        <el-form-item label="操作人">
          <el-select
            v-model="searchForm.operatorId"
            placeholder="请选择操作人"
            clearable
          >
            <el-option
              v-for="_user in operationUsers"
              :key="_user.user_id"
              :label="_user.display_name"
              :value="_user.user_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-on:click="onSearch">
            查找
          </el-button>
        </el-form-item>
        <el-form-item style="margin-right: 0">
          <el-button v-on:click="onReset">
            重置
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      id="logTable"
      ref="logTable"
      :data="dtDatum"
      stripe
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      :height="dtHeight"
      style="width: 100%"
    >
      <el-table-column
        v-for="_item in dtTitleNames"
        :key="_item.prop"
        :prop="_item.prop"
        :label="_item.label"
        :min-width="_item.width"
      >
      </el-table-column>

      <el-table-column label="操作内容" min-width="300px">
        <template slot-scope="scope">
          {{ scope.row.operation_action_description }} ：
          {{ scope.row.business_code }} - {{ scope.row.business_name }}
        </template>
      </el-table-column>

      <el-table-column label="详情">
        <template slot-scope="scope">
          <el-button
            v-on:click="onViewDetail(scope.row.log_id)"
            class="text-btn-in-dg"
            type="text"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-on:size-change="onPageSizeChange"
      v-on:current-change="onPageIdxChange"
      :current-page="pagination.page"
      :page-sizes="[20, 50, 100]"
      :page-size="pagination.size"
      align="right"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <!-- 模态框 查看详情 -->
    <el-dialog
      title="详情"
      v-if="modalLogDetail.isVisible"
      :visible.sync="modalLogDetail.isVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="modal-change-patient-group genetrials-common-dialog"
      width="820px"
    >
      <log-detail :logDetail="modalLogDetail.currLogDetail"></log-detail>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store/index.js";
import LogDetail from "./LogDetail.vue"
import dayjs from 'dayjs';

export default {
  name: "PatientList",
  components: {
    LogDetail
  },
  props: {},
  data() {
    return {
      // 分页属性
      pagination: {
        size: 20,
        page: 1,
        total: 0,
      },
      loading: false,
      dtHeight: "100",
      // 表格数据
      dtTitleNames: [],
      dtDatum: [],
      // 搜索框
      searchForm: {
        operatorId: "",
      },
      modalLogDetail: {
        isVisible: false,
        currLogDetail: {},
      }
    };
  },
  computed: {
    operationUsers() {
      return [...store.state.projectUsers]
    }
  },
  methods: {
    // 分页方法
    onPageSizeChange(val) {
      this.pagination.size = val;
      this.goPage1ReloadDt();
    },
    onPageIdxChange(val) {
      console.log("page idx change");
      this.pagination.page = val;
      this.reloadDt();
    },
    reloadPage() {
      this.goPage1ReloadDt();
      this.reloadDic();
    },
    goPage1ReloadDt() {
      this.pagination.page = 1;
      this.reloadDt();
    },
    reloadDt() {
      this.loading = true;

      var _thisVue = this;

      const params =
        `page=${_thisVue.pagination.page}&size=${_thisVue.pagination.size}`;
      const body = {
        operation_by: _thisVue.searchForm.operatorId
      }
      const payload = {
        params: params,
        body: body
      };
      console.log('payload', payload)

      // 加载日志列表
      store.dispatch("getPagedLogs", payload).then(() => {
        _thisVue.loading = false;

        _thisVue.dtDatum = store.state.operationLogs.data.map(ii => {
          ii.operation_date_format = ii.operation_date ? dayjs(ii.operation_date).format('YYYY-MM-DD HH:mm:ss') : ii.operation_date
          return ii
        });
        _thisVue.pagination.total = store.state.operationLogs.total;
        console.log("当前日志", _thisVue.dtDatum);
      });
    },
    reloadDic() {
      // 加载 基础数据
      store.dispatch("getProjectUsers")
    },
    resizeDt() {
      const zoom = document.getElementsByTagName('body')[0].style.zoom;
      this.dtHeight = document.documentElement.clientHeight / zoom - 160;
      const tb = document.querySelector("#logTable .el-table__body-wrapper");
      tb.style.height = `${this.dtHeight}px`;
      tb.style.overflowY = `auto`;
    },
    // 查询
    onSearch() {
      this.goPage1ReloadDt();
    },
    onReset() {
      this.searchForm.operatorId = "";
      this.onSearch();
    },
    // 查看详情
    onViewDetail(logId) {
      this.modalLogDetail.isVisible = true
      this.modalLogDetail.currLogDetail = this.dtDatum.find(d => d.log_id == logId)
      console.log('curr log detail', this.modalLogDetail.currLogDetail)
    },
  },
  watch: {},
  created() {
    var _thisVue = this;

    // 表头
    _thisVue.dtTitleNames = [
      {
        prop: "operation_by_name",
        label: "操作人",
        width: "140px",
      },
      {
        prop: "operation_date_format",
        label: "操作时间",
        width: "260px",
      },
      {
        prop: "source_ip",
        label: "操作IP",
        width: "140px",
      },
    ];
  },
  mounted() {
    // 载入dt
    this.reloadPage();

    // resize
    this.$nextTick(() => {
      this.resizeDt();
      window.onresize = () => {
        this.resizeDt();
      };
    });
  },
};
</script>

<style>
.log-list-container {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.log-list-container .my-row {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 0;
}

.log-list-container .text-btn-in-dg {
  margin-right: 10px;
}

.log-list-container .my-link {
}

.log-list-container .my-link:hover {
  cursor: pointer;
}
</style>
