<template>
  <div>
    <el-form
      class="q-custom-form"
      label-position="top"
      size="mini"
      :model="questionaireForm"
      :disabled="disableEdit || disableNSCLCOperation"
      ref="questionaireForm">
      <template v-for="(question, index) in questionnaireConfig">
        <div v-if="question.type === 1" :key="question.key" class="define-form-item">
          <span class="sortNo">{{ handleSortNo(index) }}</span>
          <el-form-item :label="question.label" :prop="question.key"
            :rules="[
              { required: true, message: '请输入', trigger: 'blur' },
              { validator: customValidator(question.rules), trigger: 'blur'}
            ]">
            <el-input style="display: inline-block;width: 220px" v-model="questionaireForm[question.key]" placeholder="请输入" :maxlength="question.maxlength ? question.maxlength : null" />
            <span v-if="question.unit" class="unit">{{ question.unit || '' }}</span>
          </el-form-item>
        </div>
        <div v-else-if="question.type === 2" :key="question.key" class="define-form-item">
          <span class="sortNo">{{ handleSortNo(index) }}</span>
          <q-radio
            v-model="questionaireForm[question.key]"
            :question="question"
          />
        </div>
        <div v-else-if="question.type === 3" :key="question.key" class="define-form-item">
          <span class="sortNo">{{ handleSortNo(index) }}</span>
          <el-form-item :label="question.label" :prop="question.key"
            :rules="[
              { required: true, message: '请选择', trigger: 'change' },
            ]">
            <el-date-picker placeholder="年-月-日" v-model="questionaireForm[question.key]" value-format="yyyy-MM-dd" :default-value="Date.parse(question.initalDate)" popper-class="define-patient-add-date-picker" />
          </el-form-item>
        </div>
        <div v-else-if="question.type === 4" :key="question.key" class="define-form-item child-item">
          <span class="sortNo">{{ handleSortNo(index) }}</span>
          <el-form-item v-for="(child, idx) in question.children"
            :key="child.key"
            :label="idx === 0 ? question.label: ''"
            :prop="child.key"
            :rules="[
              { required: true, message: '请输入', trigger: 'blur' },
            ]">
            <el-input style="display: inline-block;width: 100px" v-model="questionaireForm[child.key]"
              :placeholder="child.label" />
            <span v-if="idx === 0" class="unit">/</span>
            <span v-if="question.unit && idx > 0" class="unit">{{ question.unit || '' }}</span>
          </el-form-item>
        </div>
      </template>
    </el-form>
    <div class="custom-form-footer" >
      <template v-if="!disableNSCLCOperation">
        <el-button v-if="disableEdit" class="custom-submit-btn" @click="changeEditStatus">编辑</el-button>
        <el-button v-else class="custom-submit-btn" type="primary" @click="handleConfirm" :loading="loading">提交</el-button>
      </template>
    </div>
  </div>
</template>
<script>
import {
  apiModifyTask,
  apiGetTaskDetail
} from '@/apis/NSCLC/modalQuestionaire'
import ngsResult from './config/ngsResult'
import driverGene from './config/driverGene'
import demographic from './config/demographic'
import QRadio from './FormItems/QRadio.vue'
import store from '@/store'

const taskCodeMap = {
  'CTTK00020010': demographic,
  'CTTK00020240': driverGene,
  "CTTK00020160": ngsResult,
  "CTTK00020230": ngsResult,
  "CTTK00020280": ngsResult,
  "CTTK00020380": ngsResult,
  "CTTK00020480": ngsResult,
  "CTTK00020580": ngsResult,
  "CTTK00020680": ngsResult,
  "CTTK00020780": ngsResult,
  "CTTK00020880": ngsResult,
  "CTTK00020980": ngsResult,
  "CTTK00021080": ngsResult,
  "CTTK00021180": ngsResult,
  "CTTK00021280": ngsResult,
  "CTTK00021380": ngsResult,
  "CTTK00021480": ngsResult,
  "CTTK00021580": ngsResult,
  "CTTK00021680": ngsResult,
}
export default {
  props: {
    taskCode: {
      type: String,
      default: ''
    },
    patientId: {
      type: String,
      default: ''
    },
    taskStatus: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      questionaireForm: {},
      loading: false,
      disableEdit: false
    }
  },
  computed: {
    questionnaireConfig() {
      return taskCodeMap[this.taskCode] && taskCodeMap[this.taskCode].config
    },
    apiType() {
      return taskCodeMap[this.taskCode] && taskCodeMap[this.taskCode].type
    },
    disableNSCLCOperation() {
      return store.state.user.disableNSCLCOperation
    }
  },
  created() {
    if (this.taskCode) {
      this.getDetail()
    }
  },
  components: { QRadio },
  watch: {
    taskCode(val) {
      if (val) {
        this.getDetail()
      }
    }
  },
  methods: {
    customValidator(rules) {
      return (rule, value, callback) => {
        if (rules && rules.min !== undefined && rules.max !== undefined) {
          if (value >= rules.min && value <= rules.max) {
            callback();
          } else {
            callback(new Error(rules.message));
          }
        } else {
          callback();
        }
      };
    },
    async getDetail() {
      const params = {
        apiType: this.apiType,
        patient_id: this.patientId,
        task_code: this.taskCode
      }
      const res = await apiGetTaskDetail(params)
      if (res.code === 200) {
        Object.keys(res.entity).map(key => {
          this.$set(this.questionaireForm, key, res.entity[key] || '')
        })
      } else {
        this.$message.error(res.message)
      }
      this.$nextTick(() => {
        this.$refs.questionaireForm && this.$refs.questionaireForm.clearValidate()
      })
      if (this.taskStatus === '30') {
        this.disableEdit = true
      } else {
        this.disableEdit = false
      }
    },
    handleSortNo(index) {
      return index + 1 > 9 ? index + 1 : '0' + (index + 1)
    },
    handleConfirm() {
      this.$refs['questionaireForm'].validate(async valid => {
        if (valid) {
          await this.handleSubmit()
        } else {
          this.$message({
            type: 'error',
            message: '您有必填字段未填写。'
          })
        }
      })
    },
    async handleSubmit() {
      this.loading = true
      const params = {
        apiType: this.apiType,
        patient_id: this.patientId,
        task_code: this.taskCode,
        ...this.questionaireForm
      }
      const res = await apiModifyTask(params)
      this.loading = false
      if (res.code === 200) {
        this.$message.success('提交成功')
        this.disableEdit = true
        this.$emit('goNext')
      } else {
        this.$message.error(res.message)
      }
    },
    changeEditStatus() {
      this.disableEdit = false
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-submit-btn {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-60px);
  width: 120px;
}
</style>
<style lang="scss">
.q-custom-form {
  padding: 20px;
  > .define-form-item {
    padding: 5px 20px 15px;
    font-size: 12px;
    display: flex;
    background: #ffffff;
    &.child-item {
      .el-form-item {
        &:nth-child(2) {
          flex: 0 0 120px;
        }
        &:last-child {
          flex: 0 0 150px;
          margin-top: 21px;
        }
        align-content: flex-end;
      }
    }
    .el-form-item {
      flex: 1;
      margin-bottom: 0;
      .el-form-item__label {
        line-height: 16px;
        padding-bottom: 5px;
      }
      .el-form-item__content {
        line-height: 24px;
        .unit {
          color: #333;
          margin-left: 10px;
          font-size: 12px;
        }
        .define-matrix-radio {
          background: #ffffff;
          > .el-row {
            padding: 5px 20px;
            border-bottom: 1px solid #f2f2f2;
          }
          .col-justify-center {
            text-align: center;
          }
        }
        .define-radio-group-vertical {
          display: flex;
          flex-direction: column;
          > label.el-radio {
            line-height: 1.5;
          }
        }
      }
    }
    .sortNo {
      font-weight: 650;
      color: #0079fe;
      margin-right: 11px;
    }
  }
  > .define-form-item:nth-child(2n+1) {
    background: #f8f9fb;
  }
  > .define-form-description {
    padding-left: 20px;
    line-height: 36px;
    background-color: #0079fe;
    color: #ffffff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    p {
      margin: 0;
    }
  }
}
</style>