<template>
  <div class="option-out-div screen-enrollment-col">
    <div v-for="(item, key) in topSteps"
      :key="key"
      class="option-content"
      @click="openQuestionaire([item])"
      :class="item.step_status === '30' ?
        'option-inside option-inside-true' : 'option-inside option-inside-false'"
      >
      <div>
        <img v-if="item.step_status === '30'" class="icon-submit" src="@/assets/image/ok.svg" />
        <span v-else-if="item.step_status === '20'" class="circle-flag green"></span>
        <span v-else class="circle-flag"></span>
        {{ item.step_display_name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      default: () => {
        return {}
      },
      type: Object
    },
    questionnaireMenu: {
      default: () => {
        return []
      },
      type: Array
    },
    stepList: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    topSteps() {
      return this.stepList.filter(item => {
        return ['CTST00020020', 'CTST00020030'].indexOf(item.step_code) !== -1
      })
    },
  },
  methods: {
    openQuestionaire(item = []) {
      this.$emit('openQuestionaire', this.row, item[0])
    }
  }
}
</script>
<style lang="scss" scoped>
.option-out-div.screen-enrollment-col {
  div, .option-inside {
    width: 70px;
  }
}
.option-inside-true {
  vertical-align: bottom;
  .el-icon-circle-plus {
    color: #F6CAAA;
    margin-left: 3px;
  }
  .el-icon-remove {
    color: #A0D797;
    margin-left: 3px;
  }
}
.icon-submit {
  margin-right: 3px;
}
.divide-line {
  width: 1px;
  height: 12px;
  margin-left: 3px;
  background: linear-gradient(to bottom, #fff 0%, #fff 8.3%, #d4d4d4 8.3%, #d4d4d4 91.7%, #fff 91.7%, #fff 100%);
}
.circle-flag {
  align-items: center;
  border: 1px solid #333;
  border-radius: 50%;
  display: flex;
  height: 8px;
  justify-content: center;
  margin-right: 3px;
  width: 8px;
  &.green {
    border-color: #50b240;
  }
}
</style>