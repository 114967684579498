<template>
  <div class="patient-list-container">
    <el-form :inline="true" class="my-row" :model="searchForm" size="mini">
      <el-form-item label="受试者姓名">
        <el-input v-model="searchForm.name" placeholder="请输入" clearable style="width: 90px" />
      </el-form-item>
      <el-form-item label="受试者编号">
        <el-input v-model="searchForm.external_patient_code" placeholder="请输入" clearable style="width: 130px" />
      </el-form-item>
      <el-form-item label="研究中心">
        <el-select v-model="searchForm.institute_id" placeholder="请选择" size="mini" style="width: 140px" clearable>
          <el-option
            v-for="ii in instituteList"
            :key="ii.institute_id"
            :label="ii.institute_name"
            :value="ii.institute_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="负责人">
        <el-input v-model="searchForm.principal_name" placeholder="请输入" clearable style="width: 90px" />
      </el-form-item>
      <el-form-item label="添加日期">
        <el-date-picker
          style="width: 240px" 
          size="mini"
          v-model="searchForm.dateArr"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="筛选期">
        <el-select v-model="searchForm.screen_status" placeholder="请选择" style="width: 86px" clearable>
          <el-option
            v-for="item in finishStatusArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入组期">
        <el-select v-model="searchForm.enrollment_status" placeholder="请选择" style="width: 86px" clearable>
          <el-option
            v-for="item in finishStatusArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分组状态">
        <el-select v-model="searchForm.group_type" placeholder="请选择" style="width: 86px" clearable>
          <el-option
            v-for="item in groupTypeArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button type="primary" v-on:click="onSearch">
          查找
        </el-button>
        <el-button v-on:click="onReset">
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      id="patientTable"
      ref="patientTable"
      :data="dtDatum"
      stripe
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      :height="dtHeight"
      style="width: 100%"
      class="patient-table"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center" />
      <el-table-column show-overflow-tooltip prop="name" label="受试者姓名" min-width="90px" fixed="left">
        <template slot-scope="scope">
          <el-tooltip :disabled="scope.row.process_status !== '2'" effect="dark" content="该受试者已经被结束研究" placement="top">
            <span :class="{'patient-name': true, 'grey': scope.row.process_status === '2'}"
              @click="viewUserInfo(scope.row)">
              {{ scope.row.name }}
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="external_patient_code" label="受试者编号" min-width="130px" fixed="left">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.process_status === '2'"
            class="item"
            effect="dark"
            content="该受试者已经被结束研究" placement="top">
            <span :style="{'color': scope.row.process_status === '2' ? '#999' : ''}">{{ scope.row.external_patient_code }}</span>
          </el-tooltip>
          <span v-else>
            {{ scope.row.external_patient_code }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="column in tableColumns"
        :label="column.label"
        :prop="column.prop"
        :key="column.prop"
        :min-width="column.minWidth"
        :width="column.width"
      >
        <template v-if="['institute_name', 'principal_name', 'create_datetime'].includes(column.prop)" v-slot="scope">
          {{ scope.row[column.prop] || '-' }}
        </template>
        <template v-else-if="['screen_enrollment_status'].includes(column.prop)" v-slot="scope">
          <screen-enrollment-col
            :stepList="scope.row.step_list"
            :row="scope.row"
            :key="scope.row.patient_id"
            @openQuestionaire="openCommonDialog"
          />
        </template>
        <template v-else-if="'cur_process_display_name' == column.prop" v-slot="scope">
          <div
            class="project-name can-click"
            @click="jumpDetail(scope.row)"
            :key="scope.row.patient_id">
            {{ scope.row.cur_process_display_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="70px" fixed="right">
        <template slot-scope="scope">
          <el-dropdown placement="bottom" trigger="click">
            <span class="el-dropdown-link">
              <i class="el-icon-more" style="cursor: pointer;color: #0079fe"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="genetrials-common-dropdown">
              <el-dropdown-item @click.native="viewUserInfo(scope.row)">查看</el-dropdown-item>
              <template v-if="!disableNSCLCOperation">
                <el-dropdown-item @click.native="handleActionRemind('1', scope.row)">编辑</el-dropdown-item>
                <el-dropdown-item @click.native="handleDelete(scope.row)">删除</el-dropdown-item>
                <el-dropdown-item v-if="scope.row.group_type !== '90'" @click.native="handleActionRemind('3', scope.row)">
                  {{ scope.row.process_status === '2' ? '继续研究' : '结束研究'}}
                </el-dropdown-item>
                <el-dropdown-item v-if="scope.row.group_type !== '90'" @click.native="viewIncidentInfo(scope.row.patient_id)">上报不良事件</el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-on:size-change="onPageSizeChange" v-on:current-change="onPageIdxChange"
      :current-page="pagination.page" :page-sizes="[20, 50, 100]" :page-size="pagination.size" align="right"
      layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>
    <dialog-patient-add
      v-if="modalAdd.isVisible"
      :dialogVisible="modalAdd.isVisible"
      :actionType="modalAdd.actionType"
      :fromPatientId="modalAdd.fromPatientId"
      @closeDialog="modalAdd.isVisible=false"
      @refresh="refresh"
    />
    <dialog-notice
      v-if="showNotice"
      :dialogVisible="showNotice"
      :row="currentRow"
      @closeDialog="showNotice = false"
      @refresh="refresh"
    />
    <dialog-incident
      v-if="showIncident"
      hospitalName="广东省人民医院"
      msg="系统将会上报不良事件给该受试者所属研究中心的研究人员。"
      :dialogVisible="showIncident"
      :patient-id="currentRow.patient_id"
      @closeDialog="showIncident = false"
    />
    <!-- 编辑受试者、删除受试者、继续/结束研究的动作提示 -->
    <dialog-action-remind
      v-if="actionRemindVisible"
      :dialogVisible="actionRemindVisible"
      :actionRemindInfo="actionRemindInfo"
      @close="handleActionRemindClose"
      @confirm="handleActionRemindConfirm"
    />
    <dialog-commom-q
      v-if="showCommonQ"
      :dialogVisible="showCommonQ"
      :currentPatientId="currentRow.patient_id"
      :targetStep="targetStep"
      @closeDialog="showCommonQ = false; refresh()"
    />
    <dialog-delete
      v-if="showDelete"
      :dialogVisible="showDelete"
      :actionRemindInfo="actionRemindInfo"
      @close="showDelete = false"
      @confirm="handleDeleteConfirm"
    />
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import dayjs from 'dayjs';
import DialogNotice from '@/components/dialog/DialogNotice'
import DialogIncident from '@/components/dialog/DialogIncident'
import DialogPatientAdd from '@/views/NSCLC/Dialog/DialogPatientAdd'
import ScreenEnrollmentCol from './components/ScreenEnrollmentCol'
import DialogCommomQ from './Dialog/DialogCommonQ.vue'
import { getAllUserList, modifyProcess } from '@/apis/user'
import { getInstitute } from '@/apis/NSCLC/system'
import ActionRemindMixin from '@/mixins/ActionRemindMixin'
import store from "@/store/index.js";
import defaultResponseNotify from "@/helpers/message.js";
import DialogDelete from './Dialog/DialogDelete'
import {
  finishStatusArr,
  groupTypeArr
} from '@/utils/config'
export default {
  name: "IndexPage",
  mixins: [ActionRemindMixin],
  components: {
    DialogNotice,
    DialogIncident,
    DialogPatientAdd,
    ScreenEnrollmentCol,
    DialogCommomQ,
    DialogDelete
  },
  props: {
    pageType: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      tableColumns: [{
        label: '研究中心',
        prop: 'institute_name',
        minWidth: '200px',
        width: ''
      },
      {
        label: '负责人',
        prop: 'principal_name',
        minWidth: '100px',
        width: ''
      },
      {
        label: '添加日期',
        prop: 'create_datetime',
        minWidth: '110px',
        width: ''
      },
      {
        label: '筛选期             入组期',
        prop: 'screen_enrollment_status',
        minWidth: '160px',
        width: ''
      },
      {
        label: '分组状态',
        prop: 'group_type_name',
        minWidth: '120px',
        width: ''
      },
      {
        label: '当前进度',
        prop: 'cur_process_display_name',
        minWidth: '150px',
        width: ''
      }],
      // 分页属性
      pagination: {
        size: 20,
        page: 1,
        total: 0,
      },
      loading: false,
      dtHeight: "100",
      dtDatum: [],
      // 搜索框
      searchForm: {
        name: "",
        external_patient_code: "",
        institute_id: "",
        principal_name: "",
        group_type: '',
        dateArr: [],
        enrollment_status: '',
        screen_status: ''
      },
      finishStatusArr,
      groupTypeArr,
      // 负责人所属研究中心基础信息
      instituteList: [],
      // 模态框 add
      modalAdd: {
        isVisible: false,
        actionType: "1",
        patientInfo: {},
      },
      showQuestionaire: false,
      currentRow: {},
      showNotice: false,
      showIncident: false,
      multipleSelection: [],
      groupInfo: {
        '100': {
          label: '标准治疗组',
          url: '/CTPJ00000050/standard'
        },
        '200': {
          label: 'MRD指导治疗组',
          url: '/CTPJ00000050/MRD'
        },
        '210': {
          label: 'MRD指导治疗组',
          url: '/CTPJ00000050/MRD'
        },
        '220': {
          label: 'MRD指导治疗组',
          url: '/CTPJ00000050/MRD'
        },
        '90': {
          label: '未入组'
        }
      },
      dialogVisitationCorrelationVisible: false,
      dialogVisitationCorrelationInfo: {},
      showCommonQ: false,
      targetStep: '',
      showDelete: false
    };
  },
  created() {
    this.getList()
    this.getInstituteList()
  },
  computed: {
    disableNSCLCOperation() {
      return store.state.user.disableNSCLCOperation
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeDt();
      window.onresize = () => {
        this.resizeDt();
      };
    })
  },
  methods: {
    async getInstituteList() {
      const res = await getInstitute()
      if (res.code === 200) {
        this.instituteList = res.entities
      }
    },
    getList(isInit) {
      this.loading = true

      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
        ...this.searchForm,
        institute_id: this.searchForm.institute_id ? this.searchForm.institute_id : undefined,
        start_create_date: this.searchForm.dateArr.length > 0 && this.searchForm.dateArr[0] ? dayjs(this.searchForm.dateArr[0]).format('YYYY-MM-DD'): '',
        end_create_date: this.searchForm.dateArr.length > 0 && this.searchForm.dateArr[1] ? dayjs(this.searchForm.dateArr[1]).format('YYYY-MM-DD'): ''
      }
      delete params.dateArr
      getAllUserList(params).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.dtDatum = res.entities.map(item => {
            return {
              ...item,
              group_type_name: item.group_type ? this.groupTypeArr.find(group => group.value === item.group_type).label : '-',
              create_datetime: item.create_datetime ? dayjs(item.create_datetime).format('YYYY-MM-DD') : '',
            }
          })
          this.dtDatum.map(item => {
            item.step_list.sort((a, b) => {
              // 提取字符串中的数字部分
              const numA = parseInt(a.step_code.match(/\d+/)[0], 10);
              const numB = parseInt(b.step_code.match(/\d+/)[0], 10);
  
              // 从大到小排序
              return numA - numB;
            })
          })
          this.pagination.total = res.total
        }
      })
    },
    // 分页方法
    onPageSizeChange(val) {
      this.pagination.size = val;
      this.refreshList();
    },
    onPageIdxChange(val) {
      console.log("page idx change");
      this.pagination.page = val;
      this.getList();
    },
    reloadPage() {
      this.refreshList(true);
    },
    refreshList(isInit) {
      this.pagination.page = 1;
      this.getList(isInit);
    },
    getCurrentZoomLevel() {
      const screenWidth = window.screen.width;
      const windowWidth = window.innerWidth;
      return windowWidth / screenWidth;
    },
    resizeDt() {
      const zoomLevel = this.getCurrentZoomLevel();
      // 假设150%缩放大约等于0.67（这个值可能需要根据实际情况调整）
      let zoomAdjustment;
      if (zoomLevel <= 0.67) {
          zoomAdjustment = this.pageType === '3' ? 550 : 510; // 150%缩放
      } else if (zoomLevel <= 0.8) {
          zoomAdjustment = 390; // 假设125%缩放时的调整值
      } else if (zoomLevel < 1) {
          zoomAdjustment = 290; // 假设110%缩放时的调整值
      } else {
          zoomAdjustment = 160; // 100%缩放
      }
      this.dtHeight = document.documentElement.clientHeight / zoomLevel - zoomAdjustment;
      const tb = document.querySelector("#patientTable .el-table__body-wrapper");
      tb.style.height = `${this.dtHeight}px`;
      tb.style.overflowY = `auto`;
    },
    // 查询
    onSearch() {
      this.refreshList();
    },
    onReset() {
      this.searchForm = {
        name: "",
        external_patient_code: "",
        institute_id: "",
        principal_name: "",
        group_type: '',
        dateArr: [],
        enrollment_status: '',
        screen_status: ''
      }
      this.onSearch();
    },
    refresh() {
      this.onSearch()
    },
    viewUserInfo(row) {
      this.modalAdd.title = "查看";
      this.modalAdd.actionType = "2";
      this.modalAdd.isVisible = true;
      this.modalAdd.fromPatientId = row.patient_id
    },
    jumpDetail(row) {
      this.currentRow = row
      this.targetStep = row.cur_process_code === 'CTST00020010' ? 'CTST00020020' : row.cur_process_code
      this.showCommonQ = true
    },
    viewIncidentInfo(patient_id) {
      this.currentRow.patient_id = patient_id
      this.showIncident = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 修改患者信息
    onEditPatient(patientId) {
      this.modalAdd.title = "编辑";
      this.modalAdd.actionType = "3";
      this.modalAdd.isVisible = true;
      this.modalAdd.fromPatientId = patientId
    },
    openCommonDialog(row, item) {
      this.currentRow = row
      this.targetStep = item.step_code
      this.showCommonQ = true
    },
    onDeletePatient(patientId) {
      var _thisVue = this;
      const payload = {
        patient_id: patientId,
      };
      store.dispatch("deletePatient", payload).then((response) => {
        defaultResponseNotify(response);
        _thisVue.getList();
        store.dispatch("getPatientCount");
      });
    },
    endAll(row) {
      const params = {
        patient_id: row.patient_id,
        process_status: row.process_status === '1' ? 2 : 1
      }
      modifyProcess(params).then(res => {
        if (res.code === 200) {
          defaultResponseNotify(res);
          this.onSearch()
        }
      })
    },
    handleDelete(row) {
      const isEmptyStep = row.step_list.every(step => step.step_status === '10')
      this.actionRemindInfo = {
        patient_id: row.patient_id,
        process_status: row.process_status,
        isEmptyStep: isEmptyStep
      }
      this.showDelete = true
    },
    handleDeleteConfirm() {
      this.onDeletePatient(this.actionRemindInfo.patient_id)
      this.showDelete = false
    }
  }
 
};
</script>

<style lang="scss" scoped>
.patient-list-container {
  background: #fff;
  padding: 10px;
  .patient-name {
    color: #0079fe;
    cursor: pointer;
    &.grey {
      color: #999;
      cursor: default;
    }
  }
  .project-name {
    display: inline-block;
    &.can-click {
      color: #0079fe;
      cursor: pointer;
    }
  }
}

.patient-list-container .my-row {
  padding: 0;
  display: block;
}

.patient-list-container .text-btn-in-dg {
  margin-right: 10px;
}

.patient-list-container .my-link:hover {
  cursor: pointer;
}

.option-out-div div {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 24px;
  border-right: none;
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  cursor: pointer;
  img {
    margin-right: 3px;
  }
}

.second-group {
  border: 1px solid #d4d4d4;
  color: #333333;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  img {
    margin-right: 5px;
  }
  &.success {
    border: 1px solid #50b240;
    color: #50b240;
  }
}
.option-content {
  margin-right: -1px;
}
.option-inside-true {
  border: 1px solid #ddd;
  color: #50b240;
  .el-input__inner {
    color: #50b240;
  }
  .el-icon-circle-plus {
    color: #F6CAAA;
    margin-left: 3px;
  }
  .el-icon-remove {
    color: #A0D797;
    margin-left: 3px;
  }
}
.option-inside-expired {
  border: 1px solid #F6CAAA;
  // color: #eb8127;
}

.option-inside-false {
  border: 1px solid #d4d4d4;
  color: #333333;
  flex: 1;
}

.option-out-div div:last-child {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.option-out-div div:first-child {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.option-out-div div:last-child.option-inside-false {
  border-right: 1px solid #d4d4d4;
}

.option-out-div div:last-child.option-inside-true {
  border-right: 1px solid #50b240;
}

.option-out-div div:last-child.option-inside-expired {
  border-right: 1px solid #eb8127;
}

.option-out-div .icon-submit {
  width: 10px;
  color: #333;
}

</style>
