<template>
  <el-form-item :label="question.label" :prop="question.key"
    :rules="[
      { required: true, message: '请选择', trigger: 'change' },
    ]">
    <el-radio-group v-model="localValue" style="width: 100%">
      <el-row style="display: flex; align-items: center">
        <el-col :span="question.span" v-for="radio in question.options" :key="radio.label">
          <el-radio :label="radio.label">{{ radio.value }}</el-radio>
        </el-col>
      </el-row>
    </el-radio-group>
  </el-form-item>
</template>
<script>
export default {
  props: {
    value: {
      default: '',
      type: String
    },
    question: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
}
</script>