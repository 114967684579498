export default {
  type: 'driver-gene',
  config: [
  {
    key: 'driver_gene',
    label: 'NGS驱动基因',
    type: 2,
    span: 7,
    options: [
      {
        label: '10',
        value: 'EGFR敏感突变'
      },
      {
        label: '20',
        value: 'ALK融合'
      },
      {
        label: '00',
        value: '未检测到驱动基因'
      }
    ],
  }
]}