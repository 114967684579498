<template>
  <div class="user-list-container">
    <el-form :inline="true" class="my-row" size="mini">
      <div>
        <el-form-item label="研究中心">
          <el-select size="mini" v-model="selectedInstitute" multiple collapse-tags style="width: 280px" clearable>
            <el-option
              v-for="ii in institutes"
              :key="ii.institute_id"
              :label="ii.institute_name"
              :value="ii.institute_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-right: 0">
          <el-button type="primary" @click="handleSearch('search')">查找</el-button>
          <el-button @click="handleSearch('reset')">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      ref="projectUserTable"
      id="projectUserTable"
      :data="filterTableData"
      stripe
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      style="width: 100%"
    >
      <el-table-column
        v-for="_item in dtTitleNames"
        :key="_item.prop"
        :prop="_item.prop"
        :label="_item.label"
        :width="_item.width"
        :min-width="_item.minWidth"
        :row-style="{height: '20px'}"
        :cell-style="{padding:'4px'}"
      >
        <template v-slot="scope">
          {{ scope.row[_item.prop] || '—'}}
        </template>
      </el-table-column>
    </el-table>

    <!-- 模态框 添加项目成员 -->
    <el-dialog
      :title="modalAddUser.title"
      v-if="modalAddUser.isVisible"
      :visible.sync="modalAddUser.isVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="modal-add-user genetrials-common-dialog"
      width="600px"
      append-to-body
    >
    </el-dialog>
  </div>
</template>

<script>
import defaultResponseNotify from "@/helpers/message.js";
import { getUserList, addUser, removeUser, getInstitute } from '@/apis/NSCLC/system'

export default {
  name: "UserList",
  data() {
    return {
      loading: false,
      dtHeight: 1000,
      // 表格数据
      dtTitleNames: [],
      tableData: [],
      // 模态框 add
      modalAddUser: {
        title: "添加项目成员",
        isVisible: false,
      },
      // 研究中心
      institutes: [],
      // 选择的研究中心
      institute: [],
      selectedInstitute: []
    };
  },
  computed: {
    filterTableData() {
      if (this.institute && this.institute.length > 0) {
        return this.tableData.filter(item => {
          return item.groups.find(g => this.institute.includes(g.institute_id))
        })
      } else {
        return this.tableData
      }
    }
  },
  mounted() {
    var _thisVue = this;

    // 表头
    _thisVue.dtTitleNames = [
      {
        prop: "display_name",
        label: "姓名",
        width: "",
        minWidth: '85px'
      },
      {
        prop: "phone_number",
        label: "手机号",
        width: "",
        minWidth: '100px'
      },
      {
        prop: "email",
        label: "邮箱地址",
        minWidth: '180px'
      },
      {
        prop: "department",
        label: "所属单位",
        minWidth: '150px'
      },
      {
        prop: "auth_display_name",
        label: "系统角色",
        width: "",
        minWidth: '90px'
      },
      {
        prop: "institute_name",
        label: "研究中心",
        minWidth: '150px'
      },
    ];

    this.getInstituteList()
    this.getList();
    // resize
    this.$nextTick(() => {
      this.resizeDt();
      window.onresize = () => {
        this.resizeDt();
      };
    });
  },
  
  methods: {
    resizeDt() {
      console.log("height", document.documentElement.clientHeight);
      const zoom = document.getElementsByTagName('body')[0].style.zoom;
      this.dtHeight = document.documentElement.clientHeight / zoom - 160;
      const tb = document.querySelector(
        "#projectUserTable .el-table__body-wrapper"
      );
      tb.style.height = `${this.dtHeight}px`;
      tb.style.overflowY = `auto`;
    },
    getList() {
      // 加载 基础数据
      getUserList().then(res => {
        if (res.code === 200) {
          res.entities.map(item => {
            item.auth_display_name = item.roles.reduce((prev, curr, index) => {
              if (index === 0) {
                return curr.role_name;
              }
              return prev + ', ' + curr.role_name
            }, '')
            item.institute_name = item.groups ? item.groups.reduce((accumulator, current, index) => {
              if (index === 0) {
                return current.group_name;
              }
              return `${accumulator}, ${current.group_name}`;
            }, '') : '—';
          })
          this.tableData = res.entities
        } else {
          defaultResponseNotify(res)
        }
      })
    },
    getInstituteList() {
      // 加载 基础数据
      getInstitute().then(res => {
        if (res.code === 200) {
          this.institutes = res.entities
        } else {
          defaultResponseNotify(res)
        }
      })
    },
    // 添加项目成员
    onAddProjectUser() {
      this.modalAddUser.isVisible = true;

      if (this.$refs["modalAddForm"]) {
        this.$refs["modalAddForm"].resetFields();
      }
      this.dtDatum_findUser = [];

      if (this.$refs["modalAddForm"]) {
        this.$refs["modalAddForm"].resetFields();
      }
    },
    onAddedUser(user_id) {
      addUser({
        user_id
      }).then(res => {
        if (res.code === 200) {
          this.modalAddUser.isVisible = false;
          this.getList();
        } else {
          defaultResponseNotify(res)
        }
      })
    },
    onRemoveFromProject(row) {
      if (row.role_code === 'admin') return
      removeUser({
        user_id: row.user_id
      }).then(res => {
        if (res.code === 200) {
          this.getList()
        } else {
          defaultResponseNotify(res)
        }
      })
    },
    handleSearch(kind) {
      if (kind === 'reset') {
        this.selectedInstitute = []
        this.institute = []
      } else {
        this.institute = this.selectedInstitute
      }
    }
  },
  
};
</script>

<style scoped>
.user-list-container {
  /* width: 100%; */
  padding: 10px;
  background: #fff;
  border-radius: 8px;
}

.user-list-container .project-admin-container {
  width: 110px;
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  border-radius: 12px;
  background-color: #fff3ca;
  color: #ef8642;
}

.user-list-container .my-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/*表格样式*/
</style>